<template>
  <page-layout
    bgColor="#f2f2f2"
    page-name="贷款申请"
    :show-home="false"
    style="height: 100vh"
  >
    <div class="main">
      <div class="tips">申请产品：{{ pName }}</div>
      <!--			<van-search v-model="creditCode" placeholder="请输入企业社会信用代码" autofocus left-icon="" background="#ffffff" />-->

      <van-form>
        <van-cell>
          <van-field
            label="企业名称"
            v-model="formData.name"
            maxlength="35"
            readonly
            required
            clickable
            placeholder="企业名称"
            @click="handleEnterpriseSelectorShow"
          ></van-field>
          <!-- <search-input ref="search" v-model="form.creditCode"></search-input> -->
        </van-cell>
        <van-cell>
          <van-field
            label="年龄"
            v-model="formData.age"
            type="digit"
            maxlength="2"
            required
            clearable
            placeholder="申请人年龄"
          ></van-field>
        </van-cell>
        <van-cell>
          <van-field
            label="年开票额"
            v-model="formData.invoiceAmountForYear"
            type="number"
            maxlength="7"
            clearable
            placeholder="年开票额"
          >
            <template #extra>万元</template>
          </van-field>
        </van-cell>
        <van-cell>
          <van-field
            label="年缴税额"
            v-model="formData.taxAmountForYear"
            type="number"
            maxlength="7"
            clearable
            placeholder="年缴税额"
          >
            <template #extra>万元</template>
          </van-field>
        </van-cell>
        <van-cell>
          <van-field
            label="税务等级"
            :value="formData.taxGrade"
            readonly
            maxlength="1"
            clickable
            placeholder="税务等级"
            @click="handleTaxGradeSelectorShow"
          ></van-field>
        </van-cell>
      </van-form>
      <div class="apply-btn">
        <van-button
          type="primary"
          block
          round
          color="#7232dd"
          size="large"
          :loading="loading"
          loading-text="请稍后..."
          @click="createdLink"
          >立即申请
        </van-button>
      </div>
    </div>
    <van-popup
      v-model="popup.show"
      :closeable="false"
      :close-on-click-overlay="false"
      round
      position="top"
      class="popup-message"
    >
      {{ popup.message }}
    </van-popup>
    <enterprise-selector
      v-model="enterprise"
      ref="enterprise-selector"
    ></enterprise-selector>
    <tax-grade-selector
      v-model="taxGrade"
      ref="tax-grade-selector"
    ></tax-grade-selector>
  </page-layout>
</template>

<script>
import {
  Search,
  Button,
  Popup,
  Toast,
  Notify,
  Cell,
  Field,
  Form,
  Picker,
} from "vant";
import EnterpriseSelector from "@/components/EnterpriseSelector";
import TaxGradeSelector from "@/components/TaxGradeSelector";
import ApplyApi from "../../api/apply";

export default {
  name: "InputEnterprise",
  components: {
    [Search.name]: Search,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    [Notify.name]: Notify,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Form.name]: Form,
    [Picker.name]: Picker,
    EnterpriseSelector,
    TaxGradeSelector,
  },
  props: {
    pCode: String,
    orgId: String,
    staffId: String,
    v: String,
    newOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pName: "",
      formData: {
        name: "",
        creditCode: "",
        age: null,
        taxGrade: "",
        taxAmountForYear: "",
        invoiceAmountForYear: "",
      },
      enterprise: {
        enterpriseName: "",
        creditCode: "",
      },
      taxGrade: "",
      popup: {
        show: false,
        message: "",
      },
      loading: false,
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.creditCode = newVal.creditCode;
        this.formData.name = newVal.enterpriseName;
      },
    },
    taxGrade(newVal) {
      this.formData.taxGrade = newVal;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        className: "loading-toast",
      });
      ApplyApi.productInfo({
        orgId: this.orgId,
        staffId: this.staffId,
        v: this.v,
        productCode: this.pCode,
      })
        .then((res) => {
          this.pName = res.data.productName;
        })
        .catch((res) => {
          this.showPopup(res.msg ? res.msg : "系统异常，请稍后再试！");
        })
        .finally(() => {
          Toast.clear();
        });
    },
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    onTaxGradeConfirm(picker, val) {
      if (val === "无") {
        this.form.taxGrade = "";
      } else {
        this.form.taxGrade = val;
      }
      this.showTaxGradePicker = false;
    },
    handleEnterpriseSelectorShow() {
      this.$refs["enterprise-selector"].open();
    },
    handleTaxGradeSelectorShow() {
      this.$refs["tax-grade-selector"].open();
    },
    createdLink() {
      if (!this.formData.name) {
        Notify("企业名称不能为空");
        return;
      }
      if (!this.formData.age) {
        Notify("申请人年龄不能为空");
        return;
      }
      if (!this.formData.creditCode) {
        Notify("企业信用代码不能为空");
        return;
      }
      this.loading = true;
      ApplyApi.applyLink({
        productCode: this.pCode,
        name: this.formData.name,
        creditCode: this.formData.creditCode,
        orgId: this.orgId,
        staffId: this.staffId,
        v: this.v,
        age: this.formData.age,
        taxGrade: this.formData.taxGrade,
        taxAmountForYear: this.formData.taxAmountForYear,
        invoiceAmountForYear: this.formData.invoiceAmountForYear,
        newOrder: this.newOrder,
      })
        .then((res) => {
          const url = res.data.url;
          if (url.startsWith("http")) {
            Toast.loading({
              message: "加载中...",
              forbidClick: true,
              overlay: true,
              duration: 0,
              className: "loading-toast",
            });
            location.href = url;
          } else {
            this.$router.push(url);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.main {
  background-color: #ffffff;
  margin: 15px;
  border-radius: 15px;
  padding-bottom: 50px;
  font-size: 24px;
}

.tips {
  margin: 30px;
  line-height: 80px;
}

.apply-btn {
  margin: 50px 12px 50px 12px;
}

.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>